import APIService from './APIService';

export default {
  getAll() {
    return APIService.apiCall().get('/prog-geo-perdas-aneel/get-all');
  },
  getLogsCalculoPerdas(id) {
    return APIService.apiCall().get(`/prog-geo-perdas-aneel/get-logs-calculo-perdas/${id}`);
  },
  deletar(id) {
    return APIService.apiCall().delete(`/prog-geo-perdas-aneel/excluir/${id}`);
  },
  atualizaLoteOficial(id, novoValor) {
    return APIService.apiCall().patch(`/prog-geo-perdas-aneel/atualiza-lote-oficial/${id}/${novoValor}`);
  },
  cancelarCalculo(calculoId, motivoCancelamento) {
    return APIService.apiCall().post(
      `/prog-geo-perdas-aneel/cancelar-calculo/${calculoId}`,
      JSON.stringify({ motivoCancelamento })
    );
  },
  cancelarTodosCalculos(id, motivoCancelamento) {
    return APIService.apiCall().post(
      `/prog-geo-perdas-aneel/cancelar-todos-calculos/${id}`,
      JSON.stringify({ motivoCancelamento })
    );
  },
  reprocessarCtmts(id, ctmts) {
    return APIService.apiCall().post(
      `/prog-geo-perdas-aneel/reprocessar-ctmts/${id}`,
      JSON.stringify({ ctmts })
    );
  },
  save(postData) {
    return APIService.apiCall().post(
      '/prog-geo-perdas-aneel/save',
      JSON.stringify(postData)
    );
  },
  baixarLogsCalculo(calculoId) {
    return APIService.apiCall().get(`/prog-geo-perdas-aneel/baixar-logs-calculo/${calculoId}`, {
      responseType: 'blob',
      ... {
        timeout: 60 * 60 * 1000
      }
    });
  },
  baixarArquivoResultadoCalculoPerdas(id, config = {}) {
    return APIService.apiCall().get(
      `/prog-geo-perdas-aneel/baixar-arquivo-resultado-calculo-perdas/${id}`,
      { responseType: 'blob', ...config }
    );
  },
  getDadosTabelaAuxResultado(id, nomeTabela, filtros) {
    return APIService.apiCall().post(
      `/prog-geo-perdas-aneel/get-dados-tabela-aux-resultado/${id}`,
      JSON.stringify({ nomeTabela, filtros })
    );
  },
  getExportarDadosTabelaAuxResultado(id, nomeTabela, filtros, config = {}) {
    return APIService.apiCall().post(
      `/prog-geo-perdas-aneel/get-exportar-dados-tabela-aux-resultado/${id}`,
      JSON.stringify({ nomeTabela, filtros }),
      { responseType: 'blob', ...config }
    );
  }
};
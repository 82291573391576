<template>
  <div>
    <span
      class="size-status"
      style="color: purple"
      v-if="loteOficial"
    >
      <v-icon
        small
        color="purple"
        class="pb-1 icon-status"
      >
        mdi-star
      </v-icon>
      Oficial
    </span>
    <span
      class="size-status"
      style="color: gray"
      v-else
    >
      <v-icon
        small
        color="gray"
        class="pb-1 icon-status"
      >
        mdi-star-off
      </v-icon>
      Comum
    </span>
  </div>
</template>

<script>
export default {
  name: 'LoteOficial',
  props: {
    loteOficial: {
      type: Boolean,
      default: false
    }
  }
};
</script>

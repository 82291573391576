<template>
  <div>
    <v-row>
      <v-col cols="12">
        <campos-filtros
          ref="refCamposFiltrosTabelaAuxResultadoAno"
          :campos="camposTabela"
          :baseDados="nomeTabela"
          @loading="loadingDadosTabela = $event"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="blue"
          min-width="100"
          style="float: right"
          class="mt-n5 mr-0"
          @click="getDadosTabela()"
          :loading="loadingDadosTabela"
        >
          Filtrar
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="items.length">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
        >
          <template v-slot:item="row">
            <tr>
              <td
                v-for="(coluna, i) in colunasPrincipais"
                :key="i"
                :class="{ 'text-right': headersTypes[coluna] === 'numeric' }"
              >
                <span :title="`${coluna}: ${row.item[coluna]}`">
                  {{ row.item[coluna] | treatDinamic(headersTypes[coluna]) }}
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-btn
          v-if="items.length"
          color="blue"
          min-width="100"
          style="float: right"
          class="mt-2 mr-0"
          @click="getExportarDadosTabela()"
          :loading="loadingExportarDadosTabela"
        >
          Exportar
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import BasesDadosService from '@/services/BasesDadosService';
import ProgGeoPerdasAneelService from '@/services/ProgGeoPerdasAneelService';
import CamposFiltros from '@/components/relatorios/CamposFiltros.vue';
import camposFiltrosMixins from '@/mixins/camposFiltrosMixins';

export default {
  name: 'TabelaAuxResultadoAno',
  mixins: [camposFiltrosMixins],
  components: {
    CamposFiltros
  },
  props: {
    calculo: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loadingDadosTabela: false,
    loadingExportarDadosTabela: false,
    nomeTabela: 'prog_geo_perdas_aneel.AuxResultadoAno',
    camposTabela: [],
    camposTipos: [],
    selectExecutado: null,
    items: [],
    colunasPrincipais: [
      'CodAlim',
      'TipoRodada',
      'Dias',
      'EnergiaInjBase_kWh',
      'EnergiaInj_kWh',
      'DifEnergiaBaseInj_%',
      'EnergiaForncBase_kWh',
      'EnergiaFornc_kWh',
      'PerdaEnergiaTecnica_kWh',
      'PerdaEnergiaNTecnica_kWh',
      'PerdaEnergiaCobre_kWh',
      'PerdaEnergiaCobreTrafo_%',
      'PerdaEnergiaCobreTotal_%',
      'PerdaEnergiaFerro_kWh',
      'PerdaEnergiaFerroTrafo_%',
      'PerdaEnergiaFerroTotal_%',
      'PerdaEnergiaTrafo_kWh',
      'PerdaEnergiaFerroA3aA3a_kWh',
      'PerdaEnergiaTrafoA3aA3a_kWh',
      'PerdaEnergiaFerroA3aA4_kWh',
      'PerdaEnergiaTrafoA3aA4_kWh',
      'PerdaEnergiaFerroA3aB_kWh',
      'PerdaEnergiaTrafoA3aB_kWh',
      'PerdaEnergiaFerroA4A4_kWh',
      'PerdaEnergiaTrafoA4A4_kWh',
      'PerdaEnergiaFerroA4B_kWh',
      'PerdaEnergiaTrafoA4B_kWh',
      'PerdaEnergiaFerroA4A3a_kWh',
      'PerdaEnergiaTrafoA4A3a_kWh',
      'PerdaEnergiaFerroBA3a_kWh',
      'PerdaEnergiaTrafoBA3a_kWh',
      'PerdaEnergiaFerroBA4_kWh',
      'PerdaEnergiaTrafoBA4_kWh',
      'PerdaEnergiaLinhas_kWh',
      'PerdaEnergiaMT_kWh',
      'PerdaEnergiaBT_kWh',
      'PerdaEnergiaLinhasMT_kWh',
      'PerdaEnergiaLinhasMTTotal_%',
      'PerdaEnergiaLinhasBT_kWh',
      'PerdaEnergiaLinhasBTTotal_%',
      'PerdaEnergiaLinhasMTA3a_kWh',
      'PerdaEnergiaLinhasMTA4_kWh',
      'EnergiaForncMT_kWh',
      'EnergiaForncBT_kWh',
      'EnergiaPassTrafo_kWh',
      'EnergiaPassBT_kWh',
      'PerdaEnergiaTecnica_EnergiaInj_%',
      'PerdaEnergiaNTecnica_EnergiaInj_%',
      'PerdaEnergiaTecnica_EnergiaFornc_%',
      'PerdaEnergiaNTecnica_EnergiaFornc_%',
      'PerdaEnergiaTecnica_EnergiaInj_per',
      'PerdaEnergiaNTecnica_EnergiaInj_per',
      'PerdaEnergiaTecnica_EnergiaFornc_per',
      'PerdaEnergiaNTecnica_EnergiaFornc_per',
      'PropAcrsBT',
      'NumConvergiu',
      'NumDivergiu',
      'NumOverflow',
      'ResumoResultado'
    ]
  }),
  mounted() {
    this.getCamposTabela();
  },
  computed: {
    headers() {
      return this.colunasPrincipais.map((coluna) => ({
        text: coluna,
        value: coluna,
        type: this.headersTypes[coluna],
        align: this.headersTypes[coluna] === 'numeric' ? 'end' : 'start'
      }));
    },
    headersTypes() {
      const headersTypes = {};
      this.camposTipos.map(({ column, type }) => (headersTypes[column] = type));
      return headersTypes;
    }
  },
  methods: {
    getCamposTabela() {
      BasesDadosService.getBasesDadosCampos(this.nomeTabela)
        .then((camposTabela) => {
          const ignorarCampos = this.getIgnorarCamposFiltros();
          this.camposTabela = camposTabela.filter(
            (campo) => !ignorarCampos.includes(campo.column)
          );
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao recuperar campos da tabela ${this.nomeTabela}.`,
            '',
            { position: 'topRight' }
          );
        });
    },
    getFiltros() {
      const refCamposFiltros = this.$refs.refCamposFiltrosTabelaAuxResultadoAno;
      return refCamposFiltros ? refCamposFiltros.exportCampos() : {};
    },
    getDadosTabela() {
      this.loadingDadosTabela = true;
      const filtros = this.getFiltros();
      ProgGeoPerdasAneelService.getDadosTabelaAuxResultado(
        this.calculo.id,
        this.nomeTabela,
        filtros
      )
        .then(({ data }) => {
          this.items = data.dados;
          this.camposTipos = data.tipos;
          this.selectExecutado = data.query;
          console.log(this.selectExecutado);
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao recuperar o resultado da tabela ${this.nomeTabela}.`,
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loadingDadosTabela = false));
    },
    getExportarDadosTabela() {
      this.loadingExportarDadosTabela = true;
      const filtros = this.getFiltros();
      ProgGeoPerdasAneelService.getExportarDadosTabelaAuxResultado(
        this.calculo.id,
        this.nomeTabela,
        filtros,
        {
          timeout: 60 * 60 * 1000
        }
      )
        .then((res) => {
          const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
          const arquivo = `resultado-calculo-perdas-tecnicas-${this.calculo.id}-tabela-aux-resultado-ano-${timestamp}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', arquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download do arquivo resultado.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingExportarDadosTabela = false));
    }
  }
};
</script>
